






















































































import Vue from "vue";
import { Moment } from "moment";
import {
  DATE_FORMAT_REPORT,
  DEFAULT_DATE_FORMAT,
} from "@/models/constant/date.constant";
import MNotificationVue from "@/mixins/MNotification.vue";
import { FormModel } from "ant-design-vue";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { reportService } from "@/services-v2/report.service";
import { downloadFile } from "@/helpers/file-reader";
import { mapGetters } from "vuex";
import { IAuthorities } from "@/models/interface-v2/auth.interface";
export default Vue.extend({
  name: "ReportSalesDetail",
  components: {
    CSelectCustomer: () => import(/*webpackPrefetch: true*/"@/components/shared/select-customer/CSelectCustomer.vue"),
    CSelectSalesType: () => import(/*webpackPrefetch: true*/"@/components/shared/select-sales-type/CSelectSalesType.vue"),
    CSelectSalesOrder: () => import(/*webpackPrefetch: true*/"@/components/shared/select-sales-order/CSelectSalesOrder.vue"),
    CSelectInvoiceArBySo: () => import(/*webpackPrefetch: true*/"@/components/shared/select-invoice-ar-by-so/CSelectInvoiceArBySo.vue"),
  },
  mixins: [MNotificationVue],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        branchId: undefined as string | undefined,
        customerId: undefined as string | undefined,
        salesType: undefined as string | undefined,
        salesOrderId: undefined as string | undefined,
        invoiceId: undefined as string | undefined,
        date: [] as Moment[],
      },
      rules: {
        branchId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        invoiceId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        salesOrderId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        salesType: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        customerId: [
          {
            required: false,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
        date: [
          {
            required: true,
            message: () => this.$t("lbl_validation_required_error"),
          },
        ],
      },
      loading: false,
      salesOrderSearchBy: "",
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeMarketing() {
      return this.getUserPrivileges.find((item: IAuthorities) => item.key === "sales-detail-marketing");
    },
  },
  methods: {
    validateForm(): void {
      const form = this.$refs.form as FormModel;
      form.validate((valid: boolean) => {
        if (valid) {
          this.handleDownload();
        } else {
          this.showNotifValidationError();
        }
      });
    },
    async handleDownload(): Promise<void> {
      try {
        const [ startDate, endDate ] = this.form.date;
        this.loading = true;

        const searchBy: string[] = [];
        if (this.form.branchId) {
          searchBy.push(`branchId~${this.form.branchId}`);
        }
        if (this.form.customerId) {
          searchBy.push(`customerId~${this.form.customerId}`);
        }
        if (this.form.salesType) {
          searchBy.push(`salesType~${this.form.salesType}`);
        }
        if (this.form.salesOrderId) {
          searchBy.push(`soId~${this.form.salesOrderId}`);
        }
        if (this.form.invoiceId) {
          searchBy.push(`invoiceId~${this.form.invoiceId}`);
        }
        if (this.form.date.length) {
          searchBy.push(`invoiceDate>${startDate.set({ hour: 0, minute: 0, second: 0, }).format()}_AND_invoiceDate<${endDate.set({ hour: 23, minute: 59, second: 59, }).format()}`);
        }

        const q: RequestQueryParamsModel = {
          search: searchBy.join("_AND_"),
          params: `PT. GLOBAL PRATAMA WIJAYA,${startDate.format(DATE_FORMAT_REPORT)},${endDate.format(DATE_FORMAT_REPORT)}`,
          "show-price": false,
        };

        if (this.hasPrivilegeMarketing) {
          q["show-price"] = true;
        }

        const file = await reportService.salesDetail(q);
        downloadFile(
          new Blob([file]),
          `report_sales_detail_${startDate.format(DATE_FORMAT_REPORT)}_to_${endDate.format(DATE_FORMAT_REPORT)}.xlsx`
        );
      } catch (error) {
        this.showErrorMessage("notif_download_error");
      } finally {
        this.loading = false;
      }
    },
    handleClear(): void {
      this.form = {
        branchId: undefined,
        customerId: undefined,
        salesType: undefined,
        salesOrderId: undefined,
        invoiceId: undefined,
        date: [],
      };
    },
    handleBack(): void {
      this.$router.back();
    },
    onChangeCustomer(value: string): void {
      this.form.customerId = value;
      this.form.salesOrderId = undefined;
      this.form.invoiceId = undefined;

      this.salesOrderSearchBy = "";
      this.salesOrderSearchBy = this.createQuerySearchSo();
    },
    onChangeBranch(value: string): void {
      this.form.branchId = value;
      this.form.salesOrderId = undefined;
      this.form.invoiceId = undefined;

      this.salesOrderSearchBy = "";
      this.salesOrderSearchBy = this.createQuerySearchSo();
    },
    createQuerySearchSo(): string {
      const searchBy: string[] = [];
      if (this.form.branchId) {
        searchBy.push(`branch.secureId~${this.form.branchId}`);
      }

      if (this.form.customerId) {
        searchBy.push(`customer.secureId~${this.form.customerId}`);
      }

      if (this.form.salesType) {
        searchBy.push(`salesType~${this.form.salesType}`);
      }

      return searchBy.length ? searchBy.join("_AND_") : "";
    },
    createQuerySearchInvoice(): string {
      const searchBy: string[] = [];
      if (this.form.branchId) {
        searchBy.push(`branchWarehouse.secureId~${this.form.branchId}`);
      }

      if (this.form.customerId) {
        searchBy.push(`customer.secureId~${this.form.customerId}`);
      }

      if (this.form.date.length) {
        const [start, end] = this.form.date;
        searchBy.push(`invoiceDate>=${start.format()}_AND_invoiceDate<=${end.format()}`);
      }

      return searchBy.length ? searchBy.join("_AND_") : "";
    },
    onChangeSalesType(value: string): void {
      this.form.salesType = value;
      this.form.salesOrderId = undefined;

      this.salesOrderSearchBy = "";
      this.salesOrderSearchBy = this.createQuerySearchSo();
    },
    onChangeSalesOrder(value: string): void {
      this.form.salesOrderId = value;
      this.form.invoiceId = undefined;
    },
  },
});
