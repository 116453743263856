import { Api } from "@/models/class/api.class";
import {
  ReportAgingDetail,
  ReportAgingSummary,
  ReportJobCosting,
  ReportSalesValuationDetail,
  ReportStockOpname,
} from "@/models/interface-v2/report.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class ReportService extends HttpClient {
  public constructor() {
    super();
  }

  public downloadHpp(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsHpp, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadAgingDetails(params: ReportAgingDetail): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsAgingDetails, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public downloadAgingSummary(
    params: ReportAgingSummary
  ): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsAgingSummary, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public stockOpname(body: ReportStockOpname): Promise<ArrayBuffer> {
    return this.post<ArrayBuffer, ReportStockOpname>(
      Api.ReportsStockOpname,
      body,
      { responseType: "arraybuffer" }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public historyBatch(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsHistoryBatch, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public jobCosting(payload: ReportJobCosting): Promise<ArrayBuffer> {
    return this.post<ArrayBuffer, ReportJobCosting>(
      Api.ReportsJobCosting,
      payload,
      { responseType: "arraybuffer" }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public valuationDetail(
    params: ReportSalesValuationDetail
  ): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsStockValuationDetail, {
      responseType: "arraybuffer",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  public salesDetail(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsSalesDetail, {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const reportService = new ReportService();
