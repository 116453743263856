var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_sales_report_detail") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-col": { span: 4 },
            "wrapper-col": { span: 14 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { prop: "branchId", label: _vm.$t("lbl_branch") } },
            [
              _c("CSelectBranch", {
                on: { change: _vm.onChangeBranch },
                model: {
                  value: _vm.form.branchId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "branchId", $$v)
                  },
                  expression: "form.branchId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { prop: "customerId", label: _vm.$t("lbl_customer_name") }
            },
            [
              _c("CSelectCustomer", {
                on: { change: _vm.onChangeCustomer },
                model: {
                  value: _vm.form.customerId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "customerId", $$v)
                  },
                  expression: "form.customerId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_sales_type"), prop: "salesType" } },
            [
              _c("CSelectSalesType", {
                on: { change: _vm.onChangeSalesType },
                model: {
                  value: _vm.form.salesType,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "salesType", $$v)
                  },
                  expression: "form.salesType"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: _vm.$t("lbl_sales_order_number"),
                prop: "salesOrderId"
              }
            },
            [
              _c("CSelectSalesOrder", {
                attrs: { "prop-search-by": _vm.salesOrderSearchBy },
                on: { change: _vm.onChangeSalesOrder },
                model: {
                  value: _vm.form.salesOrderId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "salesOrderId", $$v)
                  },
                  expression: "form.salesOrderId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_invoice_date"), prop: "date" } },
            [
              _c("a-range-picker", {
                attrs: {
                  placeholder: [
                    _vm.$t("lbl_start_date"),
                    _vm.$t("lbl_end_date")
                  ],
                  format: _vm.DEFAULT_DATE_FORMAT
                },
                model: {
                  value: _vm.form.date,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "date", $$v)
                  },
                  expression: "form.date"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_invoice_number"), prop: "invoiceId" }
            },
            [
              _c("CSelectInvoiceArBySo", {
                attrs: {
                  "so-id": _vm.form.salesOrderId,
                  "branch-id": _vm.form.branchId,
                  "customer-id": _vm.form.customerId
                },
                model: {
                  value: _vm.form.invoiceId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "invoiceId", $$v)
                  },
                  expression: "form.invoiceId"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { "wrapper-col": { span: 14, offset: 4 } } },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleClear } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_clear")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: {
                        icon: "download",
                        type: "primary",
                        loading: _vm.loading
                      },
                      on: { click: _vm.validateForm }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }